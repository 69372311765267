/* Radial Menu */
.server-list::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
}

.hex-menu {
  position: fixed;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
  width: 300px;
}

.menu-hex-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border-radius: 15px;
  transition: all 0.3s;
  background: rgba(32, 34, 37, 0.5); /* Dark grey background */
}

.menu-hex-container:hover {
  background: rgba(54, 57, 63, 0.7); /* Slightly lighter hover effect */
  transform: translateX(10px);
}

.menu-hex-container.active {
  background: rgba(72, 76, 82, 0.7); /* Active state with medium grey */
}

.menu-hex {
  width: 60px;
  height: 69px;
  background-color: #2f3136; /* Darker grey for hex elements */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid #202225; /* Dark border to give it depth */
}

.menu-hex-label {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff; /* White text */
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.menu-hex-container:hover .menu-hex {
  background-color: #36393f; /* Lighter grey on hover */
}

.menu-hex-container.active .menu-hex {
  background-color: #4f545c; /* Slightly bluish grey for active */
  box-shadow: 0 0 15px rgba(114, 137, 218, 0.3); /* Blue shadow for active effect */
}

.radial-menu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  z-index: 1;
}

.center-hex {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 132px;
  background-color: #202225; /* Darker grey for center hex */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(114, 137, 218, 0.3); /* Blue glow for center */
  pointer-events: none;
}

.center-hex img {
  width: 120px;
}

.radial-menu .menu-item {
  position: absolute;
  width: 60px;
  height: 69px;
  background-color: #2f3136; /* Dark grey for menu items */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  outline: none;
  color: #ffffff; /* White text for icons */
  border: 1px solid #202225;
  overflow: hidden;
}

.radial-menu .menu-item:hover {
  background-color: #36393f; /* Lighter grey for hover */
  transform: scale(1.1);
  border-color: #4a5568;
  box-shadow: 0 0 20px rgba(54, 57, 63, 0.4);
}

.radial-menu .menu-item.active {
  background-color: #4f545c;
  box-shadow: 0 0 20px rgba(114, 137, 218, 0.3);
}

.radial-menu .menu-item i {
  font-size: 24px;
  color: #ffffff;
}

.radial-menu .menu-item.image-hex {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.radial-menu .menu-item.image-hex::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  transition: all 0.3s;
}

.radial-menu .menu-item.image-hex:hover::before {
  transform: scale(1.1);
}

/* Radiális menü pozíciók - 8 elem */
.radial-menu .menu-item:nth-child(1) { left: 170px; top: 0; }      /* Felső */
.radial-menu .menu-item:nth-child(2) { left: 280px; top: 40px; }   /* Jobb felső */
.radial-menu .menu-item:nth-child(3) { left: 320px; top: 165px; }  /* Jobb */
.radial-menu .menu-item:nth-child(4) { left: 280px; top: 290px; }  /* Jobb alsó */
.radial-menu .menu-item:nth-child(5) { left: 170px; top: 330px; }  /* Alsó */
.radial-menu .menu-item:nth-child(6) { left: 60px; top: 290px; }   /* Bal alsó */
.radial-menu .menu-item:nth-child(7) { left: 20px; top: 165px; }   /* Bal */
.radial-menu .menu-item:nth-child(8) { left: 60px; top: 40px; }    /* Bal felső */

.hex-content {
  position: fixed;
  top: 100px;
  right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(32, 34, 37, 0.5);
  width: 80px;
  height: 80px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hex-content i {
  font-size: 4rem;
  color: #ffffff;
}

.hex-content:hover {
  background-color: rgba(32, 34, 37, 0.7);
}

#selected-channel {
  color: var(--text-secondary); 
  background: var(--hover-bg)
}

#selected-channel:hover {
  color: #e5e6eb;
}

#selected-guild {
  border: 1px solid rgb(153, 153, 153);    
}

/* spinner */
.loaderparent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loader {
  display: block;
  margin: auto;
  position: relative;
  width: 48px;
  height: 48px;
}

.loader:before,
.loader:after {
  content:"";
  display: block;
  border: 32px solid transparent;
  border-top-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  animation: weld-rotate 2s infinite ease-in;
}

.loader:before {
  border-color: transparent  transparent transparent #FF3D00;
  animation-delay: 0.5s;
}

@keyframes weld-rotate {
  0% , 25% {transform: rotate(0deg)}
  50% , 75% {transform: rotate(180deg)}
  100% {transform: rotate(360deg)}
}

/* spinner */
.banner-img {
  width: 210px;
  border-radius: 0px 12px 0px 0px;
  border: 2px solid #2b2e33;
}

.avatar-container {
  position: relative;
  display: inline-block; 
}

.crown-icon {
  position: absolute;
  top: -20px;
  left: 1px; 
  width: 30px;
  height: 30px;
  pointer-events: none;
}

.reactions {
  margin: 5px;
}

.reaction {
  background-color: #640c0c58;
  border-radius: 5px;
  padding: 2px;
  margin-right: 4px;
  border: solid 1px #9a0000;
}

.no-friends, .no-channels {
  text-align: center;
}

.-no-friends p {
  text-align: justify;
}