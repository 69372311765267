.channels-sidebar {
  width: 210px;
  background: #1d2025;
  display: flex;
  flex-direction: column;
}

.server-header {
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #e5e6eb;
  font-weight: bold;
  border-bottom: 1px solid #2b2e33;
  cursor: pointer;
}

.server-header:hover {
  background: rgba(60, 64, 70, 0.2); /* Finom, sötétebb hover */
}

.channels-container {
  flex: 1;
  padding: 16px 8px;
  overflow-y: auto;
}

.channel-category {
  color: #60636c;
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.channel {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  margin: 1px 0;
  border-radius: 4px;
  color: #7a7f87;
  cursor: pointer;
  user-select: none;
}

.channel:hover {
  background: rgba(60, 64, 70, 0.2);
  color: #e5e6eb;
}

.channel.active {
  background: #4e54a3;
  color: #e5e6eb;
}

.channel::before {
  /**content: "#";**/
  margin-right: 6px;
  font-weight: 500;
}