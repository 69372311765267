.settings-content-container {
  display: flex;
  flex-grow: 1;
  margin: auto;
}

.settings-sidebar {
  width: 300px;
  background-color: #1e1f22;
  padding: 40px 20px;
  height: 100vh;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5);
}

.settings-sidebar h3 {
  color: #5e6167;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 25px;
}

.settings-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.settings-sidebar ul li {
  margin-bottom: 20px;
}

.settings-sidebar ul li a {
  color: #8e9297;
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding: 12px 15px;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.2s;
}

.settings-sidebar ul li a:hover {
  color: #ffffff;
  background-color: #2c2f33;
  transform: translateX(5px);
}

.settings-main-content {
  min-width: 500px;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

.settings-top-bar {
  width: calc(100% - 320px);
  background-color: #1e1f22;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.settings-top-bar .settings-close-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.settings-profile-section {
  background-color: #202225;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.settings-profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.settings-profile-header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.settings-profile-info {
  flex-grow: 1;
}

.settings-profile-header h2 {
  margin: 0;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}

.settings-profile-header p {
  color: #8e9297;
  font-size: 13px;
  margin: 5px 0;
}

.settings-profile-header button {
  background-color: #5865f2;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.settings-profile-header button:hover {
  background-color: #4752c4;
}

.settings-profile-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #1b1c1e;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: box-shadow 0.3s;
}

.settings-profile-item:hover {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}

.settings-profile-item strong {
  color: #dcddde;
  font-size: 16px;
  font-weight: 500;
}

.settings-profile-item button {
  margin: 2px;
  background-color: #4f545c;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
}

.settings-profile-item button:hover {
  background-color: #5865f2;
  transform: scale(1.05);
}

.settings-danger {
  background-color: #ed4245;
}

.settings-danger:hover {
  background-color: #ff5e5e;
}

.settings-section-title {
  color: #8e9297;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 3px;
  font-weight: 500;
}
