.prompt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.prompt-container {
  color: #f5f5f5;
  background-color: #1a1c21;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.prompt-container h1 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.prompt-container p {
  font-size: 1em;
  margin-bottom: 15px;
}

.prompt-container label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.prompt-container input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  background-color: #444;
  color: #f5f5f5;
  border-color: #666;
}

.prompt-container button {
  padding: 10px 15px;
  font-size: 0.9em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.prompt-container button:first-of-type {
  background-color: #d9534f;
  color: white;
}

.prompt-container button:first-of-type:hover {
  background-color: #c9302c;
}

.prompt-container button:last-of-type {
  background-color: #5bc0de;
  color: white;
}

.prompt-container button:last-of-type:hover {
  background-color: #31b0d5;
}