* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Arial, sans-serif;
}

body {
  background-color: #1a1c21;
  color: #c7c9d1;
  display: flex;
  height: 100%;

  flex-direction: column;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  height: 72px;
}

.main-container {
  flex-grow: 1;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #2c2f33;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #23272a;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1b1e21;
}

/** Main Container **/
.main-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/** Main Content **/
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #1a1c21;
}

.chat-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  border-bottom: 1px solid #2b2e33;
  color: #e5e6eb;
  font-weight: bold;
}

.chat-header .buttons {
  display: flex;
  gap: 20px;
}

.chat-header button {
  align-items: center;
  border: none;
  background: none;
  color: #e5e6eb;
  cursor: pointer;
  font-size: 18px;
}

.chat-header h2 {
  font-size: 24px;
  margin: 5px;
}

.chat-header p {
  font-size: 16px;
  margin: 0px 0px 5px 10px;
}

.messages-area {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.message {
  display: flex;
  gap: 16px;
  padding: 8px;
  margin: 4px 0;
  border-radius: 4px;
}

.message:hover {
  background: rgba(28, 30, 35, 0.3);
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4e54a3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  margin: 5px;
}

.user-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.message-content {
  flex: 1;
}

.message-header {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 4px;
}

.user-name {
  color: #e5e6eb;
  font-weight: 500;
}

.timestamp {
  color: #60636c;
  font-size: 12px;
}

.message-text {
  color: #c7c9d1;
  font-size: 16px;
  line-height: 1.375;
}

.chat-input-container {
  margin: 0 16px 24px;
}

.chat-input {
  width: 100%;
  padding: 12px;
  background: #2b2e33;
  border: none;
  border-radius: 8px;
  color: #e5e6eb;
  font-size: 16px;
  resize: none;
}

.chat-input:focus {
  outline: none;
  background: #2b2e33;
}

.chat-input::placeholder {
  color: #60636c;
}

/** Members Sidebar **/
:root {
  --background-primary: #1d2025;
  --background-secondary: #2b2d31;
  --accent-primary: #5865f2;
  --accent-secondary: #6d77ff;
  --text-primary: #ffffff;
  --text-secondary: #b5bac1;
  --status-online: #23a559;
  --status-idle: #f0b232;
  --status-dnd: #f23f43;
  --status-offline: #80848e;
}

.members-sidebar {
  padding: 10px;
  background: linear-gradient(135deg, var(--background-primary) 0%, var(--background-secondary) 100%);
  min-width: 260px;
  height: 100 vh;
  overflow-y: auto;
}

.members-group {
  font-size: 12px;
  color: var(--text-secondary);
  margin: 15px 0 12px 0;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.03em;
  padding-bottom: 8px;
  border-bottom: 2px solid rgba(255,255,255,0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member {
  display: flex;
  align-items: center;
  padding: 12px 13px;
  margin: 4px 0;
  border-radius: 12px;
  background: rgba(255,255,255,0.02);
  transition: box-shadow 0.3s ease;
}

.member:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.member-avatar {
  width: 35px;
  height: 35px;
  background: var(--background-secondary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary);
  border: 2px solid rgba(255,255,255,0.1);
}

.member-name {
  flex-grow: 1;
  font-size: 13px;
  font-weight: 500;
  color: var(--text-primary);
}

.status-indicator {
  width: 14px;
  height: 16px;
  margin-left: 12px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.online .status-indicator {
  background-color: var(--status-online);
}

.idle .status-indicator {
  background-color: var(--status-idle);
}

.dnd .status-indicator {
  background-color: var(--status-dnd);
}
.offline .status-indicator {
  background-color: var(--status-offline);
}

.group-counter {
  background: rgba(255,255,255,0.1);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 500;
  color: var(--text-primary);
}

/** Server Container **/
.server-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid #2b2e33;
  margin-left: 10px;
}

.server-icon-wrapper {
  position: relative;
  width: 54px;
  height: 54px;
}

.server-icon {
  width: 54px;
  height: 54px;
  background: #1d2025;
  background-size: cover;
  display: flex;
  align-items: center;
  border-radius: 14px 0px 0px 14px;
  justify-content: center;
  color: #7a7f87;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  overflow: hidden;
}

.server-bubble {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 150px;
  height: 54px;
  border-radius: 0px 12px 12px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 16px;
  color: #e5e6eb;
}

.server-maneger {
  width: 40px;
}

.server-button {
  font-size: 15px;
  background-color: #14161a;
  border: 1px solid #2b2e33;
  color: white;
  border-radius: 5px;
  padding: 2px 10px;
  margin: 5px;
}

.server-button:hover {
  color: #c7c9d1;
  background-color: rgba(60, 64, 70, 0.3);
}

.new-message {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #7a7f87;
  font-size: 10px;
  font-weight: 500;
}

.server-bubble .server-name {
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: #e5e6eb;
  font-size: 14px;
  font-weight: 500;
}