.header {
  background: #16181c;
  border-bottom: 1px solid #2b2e33;
  z-index: 100;
}

/** Servers Bar **/
.servers-bar {
  height: 72px;
  background: #16181c;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  overflow-y: hidden;
  overflow-x: auto;
}

.servers-bar::-webkit-scrollbar {
  height: 0;
}

.server-icon {
  min-width: 48px;
  height: 48px;
  background: #23262b;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  color: #c7c9d1;
  font-weight: bold;
  user-select: none;
}

.server-icon:hover {
  background: #2b2e33;
}

.profile-section {
  height: 48px;
  background-color: #14161a;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 10px;
  margin-left: auto;
  border-left: 1px solid #2b2e33;
}

.profile-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #4e54a3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.profile-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-name {
  color: #e5e6eb;
  font-size: 14px;
  font-weight: 500;
}

.profile-tag {
  color: #7a7f87;
  font-size: 12px;
}

.profile-controls {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-left: 8px;
  padding-left: 8px;
}

.profile-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7a7f87;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
}

.profile-icon:hover {
  color: #e5e6eb;
  background-color: rgba(60, 64, 70, 0.3);
}