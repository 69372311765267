.profile-preview {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 200px;
    top: 0px;
    transform: translateX(-50%);
    width: 260px;
    background: var(--bg-profile);
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: opacity 0.2s ease, transform 0.2s ease;
    z-index: 999999;
}

.member .profile-preview {
    border: 1px solid rgb(0, 0, 0);
}

.member:hover .profile-preview {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}

.preview-header {
    background: linear-gradient(45deg, #5865F2, #4752C4);
    padding: 12px;
    display: flex;
    gap: 12px;
    position: relative;
}

.preview-avatar {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    overflow: hidden;
    border: 3px solid var(--bg-profile);
}

.preview-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.preview-user-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.preview-username {
    color: white;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
}

.preview-username i {
    width: 14px;
    height: 14px;
    color: white;
}

.preview-tag {
    color: rgba(255,255,255,0.8);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.preview-content {
    padding: 12px;
}

.preview-section {
    background: var(--bg-card);
    border-radius: 6px;
    padding: 8px;
    margin-bottom: 8px;
}

.preview-section-title {
    font-size: 11px;
    text-transform: uppercase;
    color: var(--text-muted);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 6px;
}

.preview-section-title i {
    width: 12px;
    height: 12px;
}

.preview-roles {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.preview-role {
    padding: 4px 8px;
    background: rgba(88,101,242,0.1);
    color: #5865F2;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
}

.preview-role i {
    width: 12px;
    height: 12px;
}

.preview-stats {
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.preview-stat {
    flex: 1;
    text-align: center;
    padding: 6px;
    background: rgba(255,255,255,0.02);
    border-radius: 4px;
}
